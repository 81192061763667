import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface LogoProps {
  navigationHandler: Function
}

const Logo = ({ navigationHandler }: LogoProps) => (
  <StaticImage
    src="../images/otkp_logo.jpg"
    alt="logo"
    height={125}
    onClick={(_: any) => navigationHandler(-1)}
  />
)

export default Logo
