import React, { useState } from 'react'
import { createStyles, Drawer, IconButton, Theme } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SideBarMenuItems from './SideBarMenuItems'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideBarMenuButton: {
      marginRight: theme.spacing(1),
    },
  })
)

interface DrawerMenuProps {
  selectedMenuIndex: number | boolean
  handleMenuItemSelection: Function
}
const SidebarMenu = ({
  handleMenuItemSelection,
  selectedMenuIndex,
}: DrawerMenuProps) => {
  const classes = useStyles()
  const [sideBarMenuButtonEl, setSideBarMenuButtonEl] = useState(null)

  const handleSideBarMenuClick = (e: any) => {
    setSideBarMenuButtonEl(e.currentTarget)
  }

  return (
    <>
      <IconButton
        edge="start"
        className={classes.sideBarMenuButton}
        color="inherit"
        aria-label="menu"
        onClick={(e) => handleSideBarMenuClick(e)}
        size="medium"
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        anchor={'left'}
        open={sideBarMenuButtonEl !== null}
        onClose={() => setSideBarMenuButtonEl(null)}
      >
        <SideBarMenuItems
          selectedMenuIndex={selectedMenuIndex}
          handleMenuItemSelection={handleMenuItemSelection}
          setSideBarMenuButtonEl={setSideBarMenuButtonEl}
        />
      </Drawer>
    </>
  )
}

export default SidebarMenu
