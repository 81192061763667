import React from 'react'
import { createStyles, Tab, Tabs, Theme, useMediaQuery } from '@material-ui/core'
import { menuItems } from '../constants'
import { makeStyles, useTheme } from '@material-ui/core/styles'

interface MenuProps {
  selectedMenuIndex: number | boolean
  handleMenuItemSelection: Function
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: 40,
    },
    root: {
      paddingTop: 100,
      paddingBottom: 20,
      backgroundColor: theme.palette.background.default
    },
    tabIndicator: {
      backgroundColor: theme.palette.secondary.dark,
    },

    tabLabel: {
      color: theme.palette.primary.dark,
      opacity: '1.0',
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(20),
      fontFamily: theme.typography.body2.fontFamily
    },

    selectedTabLabel: {
      color: theme.palette.secondary.dark,
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(20),
      fontFamily: theme.typography.body2.fontFamily
    },
  })
)

const TopAppMenu = ({
  selectedMenuIndex,
  handleMenuItemSelection,
}: MenuProps) => {
  const classes = useStyles()

  const theme = useTheme()
  const centerMenu = useMediaQuery(theme.breakpoints.up('md'))

  const items = menuItems.map((item) => {
    const isSelected = item.menuIndex === selectedMenuIndex
    return (
      <Tab className={isSelected ? classes.selectedTabLabel : classes.tabLabel } key={item.label} label={item.label} />
    )
  })

  return (
    <Tabs
      id="tab-bar"
      classes={{ indicator: classes.tabIndicator }}
      value={selectedMenuIndex}
      centered={centerMenu}
      onChange={(evt, index) => handleMenuItemSelection(index)}
      aria-label="app bar menu"
      indicatorColor="primary"
    >
      {items}
    </Tabs>
  )
}

export default TopAppMenu
