import { AppMenuItem } from './menus/app-menu-item'

export const companyName = 'Off The Kuf Publishing, Inc.'
export const siteName = 'Off The Kuf Publishing'

export const menuItems: AppMenuItem[] = [
  { label: 'Books', menuIndex: 0, uri: '/books' },
  { label: 'Contact Us', menuIndex: 1, uri: '/contact' },
  { label: 'About', menuIndex: 2, uri: '/about' },
]

export const contactFieldValidators = {
  name: (val: string) => val.length >= 1 && val.length <= 64,
  email: { minLength: 10,
    maxLength: 128,
    verifier: (val: string) => val.indexOf('@') !== -1 },
  comment: { minLength: 10, maxLength: 512 },
}

export const JSON_MIMETYPE = 'application/json'
