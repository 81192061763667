import React, { useState } from "react";
import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"; // includes default theme
import { companyName, menuItems } from "../constants";
import Banner from "./Banner";
import Footer from "./Footer";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";

const useStyles = makeStyles((theme: Theme) => ({
  companyName: {
    marginTop: 50
  },
  content: {
    margin: "auto"
  },
  bookshelfContainer: {
    margin: "auto",
    width: "95%"
  },
  divider: {
    marginTop: 50,
    marginBottom: 5,
    height: 2,
    color: theme.palette.primary.main
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    height: "2000",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default
  }
}));

const Layout = ({ children }: any) => {
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState<number>(-1);

  const handleNavigation = (idx: number): void => {
    setMenuIndex(idx);
    idx >= 0 ? navigate(menuItems[idx].uri) : navigate("/");
  };

  const Content = () => (
    <div className={classes.content}>
      {children}
      <Divider className={classes.divider} />
    </div>
  );

  return (
    <div className={classes.root}>
      <Banner
        title={companyName}
        selectedMenuIndex={menuIndex}
        handleMenuItemSelection={handleNavigation}
      />
      <div className={classes.bookshelfContainer}>
        <StaticImage src="../images/bookshelf.jpg" alt="bookshelf" />
      </div>
      <Content />
      <Footer />
    </div>
  );
};

export default Layout;
