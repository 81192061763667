import { colors, createTheme } from '@material-ui/core'
import "@fontsource/bree-serif/400.css"
import "@fontsource/yrsa/"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: `#000066`,
      light: `#0000FF`,
      dark: `#000080`,
    },
    secondary: {
      main: `#C82601`,
      light: `#F82601`,
      dark: `#982601`,
    },
    text: {
      primary: `#000`,
      secondary: `#fff`,
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
  typography: {
    fontFamily: 'Yrsa, Serif, Bree Serif, Sans Serif',
    body1: {
      fontSize: '1.0rem',

      '@media (min-width:400px)': {
        fontSize: '1.2rem',
      },

      '@media (min-width:800px)': {
        fontSize: '1.4rem',
      },

      '@media (min-width:1200px)': {
        fontSize: '1.6rem',
      },
    },
    body2: {
      fontSize: '1.0rem',
      fontWeight: 'normal',
      fontFamily: 'Bree Serif, Arial, Sans-Serif',

      '@media (min-width:400px)': {
        fontSize: '1.2rem',
      },

      '@media (min-width:800px)': {
        fontSize: '1.4rem',
      },

      '@media (min-width:1200px)': {
        fontSize: '1.6rem',
      },
    },
    button: {
      fontSize: 'rem',
      fontWeight: 'normal',
      fontFamily: 'Bree Serif, Arial, Sans-Serif',
    }
  }
})

export default theme
