import React from 'react'
import {
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import BookIcon from '@material-ui/icons/ImportContacts'
import PeopleIcon from '@material-ui/icons/People'
import { StaticImage } from 'gatsby-plugin-image'
import "@fontsource/bree-serif"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      // backgroundColor: theme.palette.background.paper,
    },
    menuIcon: {
      color: theme.palette.primary.main,
    },
    menuIconSelected: {
      color: theme.palette.secondary.main,
    },
    menuItem: {
      color: theme.palette.primary.dark,
      opacity: '1.0',
      fontWeight: "bold",
      fontFamily: theme.typography.body2.fontFamily
    },
    selectedMenuItem: {
      color: theme.palette.secondary.main,
      opacity: '1.0',
      fontWeight: "bold",
      fontFamily: theme.typography.body2.fontFamily
    },
  })
)

interface SideBarMenuProps {
  selectedMenuIndex: number | boolean
  handleMenuItemSelection: Function
  setSideBarMenuButtonEl: Function
}

interface MenuItemProps {
  icon: any,
  label: string
  menuIndex: number
  selectedMenuIndex: number | boolean
  selectMenuItem: (index: number) => void
}
const MenuItem = ({icon, label, menuIndex, selectedMenuIndex, selectMenuItem}: MenuItemProps) => {
  const classes = useStyles()

  const isMenuSelected = selectedMenuIndex === menuIndex
  return (
    <ListItem
      button
      key={label}
      selected={isMenuSelected}
      onClick={(_) => selectMenuItem(menuIndex)}
      alignItems="center">
      <ListItemIcon classes={{root: isMenuSelected ? classes.menuIconSelected : classes.menuIcon}}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} classes={{primary: isMenuSelected ? classes.selectedMenuItem : classes.menuItem}}/>
    </ListItem>
  )
}

const SideBarMenuItems = ({
  selectedMenuIndex,
  handleMenuItemSelection,
  setSideBarMenuButtonEl,
}: SideBarMenuProps) => {

  const selectSideBarMenuItem = (selectedIndex: number) => {
    handleMenuItemSelection(selectedIndex)
    setSideBarMenuButtonEl(null)
  }

  return (
    <List>
      <ListItem
        button
        key={'title'}
        alignItems="flex-start"
        onClick={(_: any) => handleMenuItemSelection(null)}
      >
        <StaticImage
          src="../images/otkp_logo.jpg"
          alt="logo"
          height={90}
        />
      </ListItem>
      <Divider />
      <MenuItem label='Books' menuIndex={0} icon={<BookIcon />}
                selectedMenuIndex={selectedMenuIndex} selectMenuItem={selectSideBarMenuItem}/>
      <MenuItem label='Contact Us' menuIndex={1} icon={<EmailIcon />}
                selectedMenuIndex={selectedMenuIndex} selectMenuItem={selectSideBarMenuItem}/>
      <MenuItem label='About' menuIndex={2} icon={<PeopleIcon />}
                selectedMenuIndex={selectedMenuIndex} selectMenuItem={selectSideBarMenuItem}/>
    </List>
  )
}

export default SideBarMenuItems
