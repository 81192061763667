import React from 'react'
import { createStyles, Grid, Hidden, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TopAppMenu from '../menus/TopAppMenu'
import SidebarMenu from '../menus/SideBarMenu'
import Logo from '../components/Logo'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    menubar: {
      paddingTop: 10,
    },
    root: {
      paddingBottom: 30,
      paddingTop: 10,
      height: 120,
      width: '95%',
      margin: 'auto',
    },
    logo: {
      paddingBottom: 10,
      margin: 'auto',
    },
    title: {
      paddingLeft: '3.2em',
    },
  }),
)

interface BannerProps {
  selectedMenuIndex: number | boolean
  handleMenuItemSelection: Function
  title: string
}

const Banner = ({
                  selectedMenuIndex,
                  handleMenuItemSelection,
                }: BannerProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <Grid container direction='row' alignItems='center' justifyContent='center'>
          <Grid item xs={2}>
            <SidebarMenu
              selectedMenuIndex={selectedMenuIndex}
              handleMenuItemSelection={handleMenuItemSelection}
            />
          </Grid>
          <Grid item xs={10} className={classes.logo}>
            <Logo navigationHandler={handleMenuItemSelection} />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container direction='row' alignItems='flex-end'>
          <Grid item md={6} className={classes.logo}>
            <Logo navigationHandler={handleMenuItemSelection} />
          </Grid>
          <Grid item md={6}>
            <TopAppMenu
              selectedMenuIndex={selectedMenuIndex}
              handleMenuItemSelection={handleMenuItemSelection}
            />
          </Grid>
        </Grid>
      </Hidden>
    </div>
  )
}

export default Banner
