import React from 'react'
import { Container, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    marginTop: 5,
  },
  footerText: {
    marginTop: 5,
    textAlign: 'center',
  },
}))

const Footer = () => {
  const classes = useStyles()

  const currentYear = new Date().getFullYear()
  const yearRange = currentYear > 2021 ? `2021 - ${currentYear}` : '2021'

  return (
    <Container className={classes.footerContainer} maxWidth={false}>
      <Typography className={classes.footerText} variant="h5" color='primary'>
        &copy; {yearRange} Off The KUF Publishing, Inc.
      </Typography>
    </Container>
  )
}

export default Footer
