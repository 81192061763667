import React from 'react'
import Layout from './src/layout/Layout'
import { ThemeProvider } from '@material-ui/core'
import theme from './src/theme'

// element needs to be a React.ReactNode
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
